.wrapper {
  height: 90vh;
}

.passwordInput {
  position: relative;
}

.passwordInput svg {
  bottom: 13px;
  position: absolute;
  right: 10px;
  width: 16px;
}

.passwordField {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.passwordInput input {
  padding-right: 30px !important;
}
.passwordInput :global(.form-control):invalid {
  background-position: right calc(0.375em + 1.1875rem) center !important;
}
