.formContent {
    z-index: 1;
}

.addForm {
    padding: 0 50px !important;
}
.form {
    width: 30% !important;
}
.formMain {
    padding: 0 3rem !important;
}

.passwordInput {
    position: relative;
}

.passwordInput svg {
    bottom: 13px;
    position: absolute;
    right: 10px;
    width: 16px;
}

.passwordField {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.logoemail{
    position:relative;
    left:17rem;
     margin-bottom:-4.8rem

}
@media screen and (max-width: 1024px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 100% !important;
    }
 }

@media screen and (max-width: 768px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 100% !important;
    }
    .formMain {
        padding: 0 1rem !important;
    }
    .logoemail{
        position:relative;
        left:16rem;
         margin-bottom:-4.8rem
    
    }
}