nav {
  z-index: 1;
}

.signOut {
  color: #5932ea;
  cursor: pointer;
}

.name {
  width: 36px;
  height: 36px;
  color: #fff;
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  text-transform: uppercase;
}
.notificationBadge {
  background: #d957f9;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: absolute;
  bottom: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notificationCount {
  font-size: 11px;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .navItem {
    flex-direction: row-reverse;
  }
  .logo {
    margin-left: -25px;
  }
  .notificationBadge {
    background: #d957f9;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    position: absolute;
    bottom: -4px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notificationCount {
    font-size: 10px;
    color: #fff;
  }
}
