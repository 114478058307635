.navTabs :global(.nav-link) {
    background-color: #FFFFFF !important;
    color: rgba(149, 55, 255, 1) !important;
    padding: 7px !important;
    font-size: 14px;
    border: 1px solid rgba(222, 223, 228, 1) !important;
}

.navTabs :global(.nav-link.active) {
    background-color: rgba(149, 55, 255, 1) !important;
    color: #FFFFFF !important;
}


@media screen and (max-width: 820px) {
    .flexGap {
        margin-bottom: 8px;
    }
}