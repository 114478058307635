.wrapper {
  padding: 60px;
}

.grid {
  padding: 0px 20px;
  display: grid;
  grid-template-columns: minmax(320px, auto) minmax(320px, auto);
  column-gap: 40px;
  row-gap: 30px;
}

.fullWidth {
  grid-column: 1/3;
}

.flexAlign {
  display: flex;
  color: grey;
  align-items: center;
  gap: 5px;
}

/* .wrapper {
    height: 90vh;
} */

.usernameInput {
  position: relative;
}

.usernameInput .gButton {
  bottom: 1px;
  position: absolute;
  right: 25px;
  width: 16px;
}

.generateButton {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.flexRadio {
  display: flex;
  align-items: center;
  gap: 27px;
  margin-top: 25px;
}

.radiolabel {
  display: flex;
  gap: 10px;
}

.hr {
  border: 1px dashed #dedfe4;
}

.imageInput {
  display: block;
  width: 100%;
  border: 1px dashed #dedfe4;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 10px 0px;
  padding-left: 15px;
}

.imageInput .img {
  display: flex;
  justify-content: center;
  padding: 15px;
}

/* .reactSelectContainer__control {
  width: 100%;
  max-width: 100%;
} */

:local(.multiSelect) :global(.react-select-container__value-container) {
  overflow: auto;
  height: 36px;
}
:local(.multiSelect) :global(.react-select-container__value-container)::-webkit-scrollbar {
  width: 0.3em;
}
:local(.multiSelect) :global(.react-select-container__value-container)::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
:local(.multiSelect) :global(.react-select-container__value-container)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.trialDateInput :global(.form-control):invalid {
  background-position: right calc(0.375em + 1.1875rem) center !important;
}
@media screen and (max-width: 950px) {
  .grid {
    padding: 0px 20px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 40px;
    row-gap: 30px;
  }
}

@media screen and (max-width: 636px) {
  .grid {
    padding: 0px 7px;
    display: grid;
    grid-template-columns: auto;
    column-gap: 40px;
    row-gap: 30px;
  }
  .fullWidth {
    grid-column: 1/2;
  }
  .wrapper {
    padding: 35px;
  }
}
