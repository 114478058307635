.formCard {
    min-width: 400px !important;
    width: auto;
    padding: 3rem;
    max-width: 800px;
    box-shadow: 0px 20px 45px #F0EDF6 !important;
}

@media screen and (max-width: 636px) {
    .formCard {
        padding: 0.7rem;
        min-width: 300px !important;
    }
}