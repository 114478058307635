body {
  font-family: "Cabin", sans-serif;
  background: #f5f6fa !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  font-family: "Cabin", sans-serif;
}

p,
span,
a {
  letter-spacing: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.link {
  color: #8834ff;
  text-decoration: none;
}

.link:hover {
  color: #8834ff;
}

.text-success {
  color: rgba(36, 153, 62, 1) !important;
}

.text-danger {
  color: rgba(240, 104, 129, 1) !important;
}

input:focus {
  box-shadow: none !important;
  border: 1px solid #d957f9 !important;
}

input[type="checkbox"]:checked {
  background-color: #da57f9;
  border: 1px solid #da57f9;
}

input[type="radio"]:checked {
  background-color: #da57f9;
  border: 1px solid #da57f9;
}

input::placeholder,
select::placeholder {
  /* font-style: italic; */
  font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  --moz-appearance: textfield;
}

.cardShadow {
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
}

.btn {
  padding: 0.5rem 0.75rem;
  border: none;
  border-color: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
}

.btn-primary {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  color: #fff;
  border: none;
  border-color: none;
}

.btn-outline-primary {
  color: #8834ff;
  border: 1px solid #8834ff;
}

.btn-outline-primary:hover {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  color: #fff;
  border: 1px solid #8834ff;
}

button:focus {
  box-shadow: none !important;
  /* border: none;
  border-color: none !important; */
}

input:invalid[focused="true"] {
  border: 1px solid red !important;
}

input:invalid[focused="true"] ~ div {
  display: block;
  color: red;
}

.shadowMain {
  box-shadow: 0px 2px 4px rgba(39, 53, 70, 0.1);
}

.purple:focus {
  color: #5932ea !important;
  background-color: #f5f6fa !important;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f8f7ff !important;
}

/* React Select */
.react-select-container__control--is-disabled {
  background: #e9ecef;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-b62m3t-container {
  width: 100%;
  max-width: 100%;
}

.react-select-container__control {
  display: flex;
}
.react-select-container__input-container input {
  border: 0px !important;
}
.react-select-container__control {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* Validation */
.invalid-text {
  display: none;
  color: red;
}

input[data-touched="true"]:invalid,
select[data-touched="true"]:invalid,
textarea[data-touched="true"]:invalid {
  border: 1px solid red !important;
}

input[data-touched="true"]:invalid ~ .invalid-text,
select[data-touched="true"]:invalid ~ .invalid-text,
textarea[data-touched="true"]:invalid ~ .invalid-text {
  display: block;
}

.modal-content {
  border: none !important;
  border-radius: 6px;
}

/* Tabs */
.nav-tabs .nav-link {
  color: #80858c;
  border: 0;
}

.nav-tabs .nav-link:hover {
  color: #8834ff;
  border: 0;
  border-bottom: 2px solid #8834ff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #8834ff;
  border: 0;
  border-bottom: 2px solid #8834ff;
}

.dropdown-toggle::after {
  display: none;
}

/* Markdown */
.jodit-status-bar-link {
  display: none;
}

.tox-statusbar__branding {
  display: none;
}
.tooltipp {
  position: relative;
  display: flex;
}
.tooltipp .tooltiptext {
  visibility: hidden;
  width: 5rem;
  background-color: #9537ff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  font-size: 12px;
  /* padding: 20px; */
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 26%;
  right: 104%;
}
.tooltipp:hover .tooltiptext {
  visibility: visible;
}

.tooltippp {
  position: relative;
  display: flex;
}
.tooltippp .tooltiptextfilter {
  visibility: hidden;
  width: 5rem;
  background-color: #9537ff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  font-size: 12px;
  /* padding: 20px; */
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 3rem;
  right: 1rem;
}
.tooltippp:hover .tooltiptextfilter {
  visibility: visible;
}

.fs-17 {
  font-size: 17px;
}

/* DateRangePicker Wrapper Sytel */
.react-bootstrap-daterangepicker-container {
  width: 100%;
  border: 1px solid #d6dbe0;
  border-radius: 6px;
}

/*STYLE for BACK BUTTON*/
.backFormButton {
  border-radius: 4px;
  background-color: #a439ff;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  padding: 8px;
  width: 75px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 3px;
}

.backFormButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.backFormButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  left: -16px;
  transform: rotate(180deg);
  transition: 0.5s;
}

.backFormButton:hover span {
  padding-left: 20px;
}

.backFormButton:hover span:after {
  opacity: 1;
  left: 0;
}

/* .tox-tinymce{

    z-index: -100 !important;
  } */
/*END OF --------STYLE for BACK BUTTON*/

/* .react-select > div:first-of-type + div{
    z-index: 3;
} */
.tox-editor-header {
  z-index: inherit !important;
}

.Toastify__progress-bar {
  background-color: #9537ff !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #ced4da !important;
  background-image: none !important;
}

.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #da57f9 !important;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #da57f9 !important;
}

.form-select:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

@media screen and (max-width: 820px) {
  .flexGap {
    margin-bottom: 5px;
  }
}

/* DateRangePicker Wrapper Sytel */
.react-bootstrap-daterangepicker-container {
  width: 100%;
  border: 1px solid #d6dbe0;
  border-radius: 6px;
}
