.searchInput {
    /* border-radius: 50px !important; */
    padding: .375rem 1.5rem !important;
}

.searchIcon {
    position: absolute;
    top: 7px;
    left: 3px;
    display: flex;
}

.search{

     padding-left:2rem !important

}
.iconBorder {
    padding: 6px;
    border: 1px solid;
    border-radius: 6px;
}


@media screen and (max-width: 768px) {
    .searchIcon {
        color: #9537FF;
        position: relative;
        top: 1px;
        left: 1px;
        display: none !important;
    }
    .searchInput {
        /* border-radius: 50px !important; */
        padding: .375rem 1.5rem !important;
    }
}