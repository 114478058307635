.border {
    border: 1px dashed #DEDFE4;
    border-radius: 6px;
    gap: 15px
}

.browseTxt {
    color: rgba(89, 50, 234, 1);
}

.txt {
    font-size: 14px;
}

.txtSize {
    font-size: 12px;
    display: block;
}

:local(.image) {
    margin: 0.5rem 0.5rem;
    position: relative;
    box-shadow: rgba(0,0,0,0.05) 0px 1px 2px 0px;
    width: 10rem;
    height: 10rem;
    border-radius: 5px;
    
    /* border: 0.5px solid rgba(0,0,0,0.12); */
}